@use '@/styles/utils/mixins.scss' as *;

.awardSectionWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 556px;

  @media screen and (min-width: 1120px) {
    padding: 48px 0px;
  }
  @include tab() {
    flex-direction: column;
  }

  .awardItem {
    min-height: 256px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;
    @include tab() {
      min-height: auto;
      margin: 40px 0;
    }
    .award {
      max-width: 417px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include tab() {
        height: auto;
        max-width: none;
      }
      img {
        width: 150px !important;
        height: 190px !important;
        position: static !important;
        margin-bottom: 18px;
      }
      .awardText {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        @include tab() {
          font-size: 16px;
          line-height: 19.2px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
